.services__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service h3 {
  margin-top: 1rem;
}

.services__container a:hover {
  opacity: 0.8;
  cursor: pointer;
}

.service img{
  width: 250px;
  height: 250px;
  border-radius: 3rem;
}

.service:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.service__head {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}

.service__list {
  padding: 2rem;
}

.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service__list-icon {
  color: var(--color-primary);
  margin-top: 2px;
}

.service__list p {
  font-size: 0.9rem;
}

/* =============== MEDIA QUERIES (MEDIUM SIZE DEVICES) ================== */
@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .service {
    height: auto;
  }
}

/* =============== MEDIA QUERIES (SMALL SIZE DEVICES) ================== */
@media screen and (max-width: 600px) {
  .services__container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
